<template>
  <div>
    <div class="tc-report-main-mmpi-149"
         v-for="(item, key) in tableInfoList" :key="key">
      <!-- 普通报告 -->
      <div class="mmpi-149-content" id="content_a" :style="{ fontSize }" v-if="!item.results">
        <!-- 标题 -->
        <div class="mmpi-149-report-title">
          <p>{{ item.measure_title }}</p>
        </div>
        <!-- 用户信息 -->
        <div class="mmpi-149-userInfo" v-show="form.scope_basic == 1">
          <table>
            <tr>
              <td>
                姓名：
                <span>{{ item.customer.name }}</span>
              </td>
              <td>
                性别：
                <span>{{ item.customer.sex }}</span>
              </td>
              <td>
                <!-- 出生日期：
                  <span>{{ item.customer.birthday }}</span> -->
                年龄：
                <span>{{ item.customer.ages }}</span>
              </td>
            </tr>
            <tr>
              <td>
                编号：
                <span>{{ item.customer.account }}</span>
              </td>
              <td>
                科室：
                <span>{{ item.customer.department }}</span>
              </td>
              <td>
                文化程度:
                <span>{{ item.customer.culture }}</span>
              </td>
              <!-- <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td> -->
            </tr>
            <template v-if="item.customer.archives">
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(0, 3)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(3, 6)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(6, 9)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(9, 12)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <!-- 测试结果title -->
        <p class="mmpi-149-test-msg" v-if="!isGraph" v-show="form.scope_graph == 1">
          测试结果:
        </p>
        <!-- 测试-图 -->
        <div class="mmpi-149-chart" v-if="!isGraph" v-show="form.scope_graph == 1">
          <!-- 90,圆形图 -->
          <div :id="'chart-report' + key" :style="{ width: '100%', height: '100%' }"></div>
        </div>
        <!-- 测试-表 -->
        <div class="mmpi-149-table" v-if="!isDisableFactor" v-show="form.scope_factor == 1">
          <div class="mmpi-149-table-main">
            <el-row v-if="item.factor_results.length <= 3">
              <!-- 因子数小于三 -->
              <el-col>
                <table class="mmpi-149-one">
                  <tr>
                    <th>检测项目</th>
                    <th v-if="!isLevel">程度</th>
                    <th v-if="!isScore">{{ scoreText || "得分" }}</th>
                  </tr>
                  <!-- -------------检测项目---------------- -->

                  <tr v-for="(item2, key2) in iszp
                      ? item.factor_results.slice(1)
                      : item.factor_results" :key="key2">
                    <td>{{ item2.name }}</td>
                    <td v-if="!isLevel">{{ item2.mark }}</td>
                    <td v-if="!isScore">{{ item2.score }}</td>
                  </tr>
                </table>
              </el-col>
            </el-row>
            <el-row v-if="item.factor_results.length > 3">
              <table class="mmpi-149-results-two" cellspacing="0">
                <thead>
                <tr>
                  <th>检测项目</th>
                  <th v-if="!isLevel">程度</th>
                  <th v-if="!isScore">{{ scoreText || "得分" }}</th>
                  <th>检测项目</th>
                  <th v-if="!isLevel">程度</th>
                  <th v-if="!isScore">{{ scoreText || "得分" }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item2, key2) in item.factor_results" :key="key2">
                  <template v-if="item.factor_results[key2 * 2 + 1]">
                    <td>{{ item.factor_results[key2 * 2 + 1].name }}</td>
                    <td v-if="!isLevel">
                      {{ item.factor_results[key2 * 2 + 1].mark }}
                    </td>
                    <td v-if="!isScore">
                      {{ item.factor_results[key2 * 2 + 1].score }}
                    </td>
                  </template>
                  <template v-if="
                        iszp
                          ? item.factor_results[(key2 + 1) * 2]
                          : item.factor_results[key2 * 2]
                      ">
                    <td>
                      {{
                        iszp
                            ? item.factor_results[(key2 + 1) * 2].name
                            : item.factor_results[key2 * 2].name
                      }}
                    </td>
                    <td v-if="!isLevel">
                      {{
                        iszp
                            ? item.factor_results[(key2 + 1) * 2].mark
                            : item.factor_results[key2 * 2].mark
                      }}
                      <!-- {{ item.factor_results[key2 * 2].mark }} -->
                    </td>
                    <td v-if="!isScore">
                      {{
                        iszp
                            ? item.factor_results[(key2 + 1) * 2].score
                            : item.factor_results[key2 * 2].score
                      }}
                      <!-- {{ item.factor_results[key2 * 2].score }} -->
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </el-row>
          </div>
        </div>
        <p v-if="iszp" class="mmpi-149-test-msg">总评:</p>
        <div v-if="iszp && editState" style="margin-top: 10px">
          <el-input v-model="zpcomment" type="textarea" :rows="10" placeholder="请输入内容"></el-input>
        </div>
        <div v-if="iszp && !editState" style="margin-top: 10px" v-html="zpcomment"></div>
        <!-- 测试结果title -->
        <p v-if="!isResultReport" class="mmpi-149-test-msg" v-show="
            form.scope_explain != 0 ||
            form.scope_explain != 0 ||
            form.scope_suggest != 0
          ">
          报告分析:
        </p>
        <!-- 每个项目分析 -->
        <div v-if="!isResultReport" class="mmpi-149-analyze" v-show="
            form.scope_explain != 0 ||
            form.scope_explain != 0 ||
            form.scope_suggest != 0
          ">
          <!-- v-show  隐藏没内容的 因子 -->
          <table v-for="(item2, key2) in item.factor_results" :key="key2"
                 v-show="item2.comment != '' || item2.advice != ''">
            <!--  v-show="item2.comment" -->
            <tr class="mmpi-149-analyze-title" v-show="form.scope_score == 1">
              <span v-if="!editState">【{{ item2.name }}】</span>
              <span v-if="editState">
                  检测项目
                  <el-input v-model="item2.name" placeholder="请输入内容"></el-input>
                </span>
              <em v-if="!isLevel && !editState" style="font-style: normal">
                分数级别：【{{ item2.mark }} 】</em>
              <el-input v-model="item2.mark" placeholder="请输入内容" v-if="editState"></el-input>
              <span v-if="!editState"><em v-if="!isScore" style="font-style: normal">得分：{{ item2.score }}</em></span>
              <el-input v-model="item2.score" placeholder="请输入内容" v-if="editState"></el-input>
              <!-- v-if="item2.comment" -->
            </tr>
            <tr v-if="item2.comment" v-show="form.scope_explain == 1">
              <td class="mmpi-149-analyze-title">【说 明】</td>
            </tr>
            <tr v-if="item2.comment" v-show="form.scope_explain == 1">
              <td v-html="item2.comment" v-if="!editState"></td>
              <td>
                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="item2.comment"
                          v-if="editState"></el-input>
              </td>
            </tr>
            <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
              <td class="mmpi-149-analyze-title">【指导建议】</td>
            </tr>
            <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
              <td v-html="item2.advice" v-if="!editState"></td>
              <td>
                <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="item2.advice"
                          v-if="editState"></el-input>
              </td>
            </tr>
          </table>
        </div>
        <!-- 配合程度 -->
        <div class="mmpi-149-degree" style="margin: 10px 0" v-if="isDegree">
          <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
          <el-select v-model="uid.degree" placeholder="请选择配合程度">
            <el-option label="低" value="低"></el-option>
            <el-option label="中" value="中"></el-option>
            <el-option label="高" value="高"></el-option>
          </el-select>
        </div>

        <!-- 医生签名 -->
        <div class="mmpi-149-signature">
            <span class="mmpi-149-title">
              <!-- <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评师:_______________</span
                > -->
              测评师 ：
              <span class="mmpi-149-signature_img">
                <img :src="item.admin.sign_img" alt width="150"/>
              </span>
            </span>
          <!-- <span class="mmpi-149-title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span> -->
          <span @dblclick="modificationDate">
              <span class="mmpi-149-title mmpi-149-data">报告日期 ：</span>
              <span v-show="!editStateFlag">{{ item.customer.evaluating_time }}</span>
              <span v-show="editStateFlag">
                <span class="mmpi-149-block">
                  <el-date-picker v-model="specificTime" type="datetime" @change='change' format="yyyy-MM-dd HH:mm:ss"
                                  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                  </el-date-picker>
                </span>
              </span>
            </span>
        </div>

        <div class="mmpi-149-user-answers mmpi-149-atable" v-if="form.scope_question == 1">
          <p class="mmpi-149-test-msg">用户答案:</p>

          <table v-if="item.question && form.scope_question == 1" cellspacing="0" style="
                                width: 100%;
                                border-top: 1px dashed black;
                                border-left: 1px dashed black;
                              ">
            <thead>
            <tr>
              <th style="
                                      padding: 5px 0;
                                      border-bottom: 1px dashed black;
                                      border-right: 1px dashed black;
                                      width: 160px;
                                    ">
                问题
              </th>
              <th style="
                                      padding: 5px 0;
                                      border-bottom: 1px dashed black;
                                      border-right: 1px dashed black;
                                      width: 180px;
                                    ">
                答案
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item2, key) in item.question" :key="key">
              <td v-html="item2.question" style="
                                      box-sizing: border-box;
                                      padding: 2px 5px 2px 5px;
                                      vertical-align: middle;
                                      border-bottom: 1px dashed black;
                                      border-right: 1px dashed black;
                                      text-align: center;
                                      width: 160px;
                                    "></td>
              <td v-html="'' + item2.answer" style="
                                      box-sizing: border-box;
                                      padding: 2px 5px 2px 5px;
                                      vertical-align: middle;
                                      border-bottom: 1px dashed black;
                                      border-right: 1px dashed black;
                                      text-align: center;
                                      width: 160px;
                                    "></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 明尼苏达多相个性测查表 -->
      <div v-if="item.results" class="mmpi-149-content mmpi-149-minnesota" :style="{ fontSize }">
        <!-- 标题 -->
        <div class="mmpi-149-unit-title" style="display: none">
          <p>{{ config.unit_name }}</p>
          <br>
          <div></div>
        </div>
        <!-- 标题  -->
        <div class="mmpi-149-report-title">
          <p>{{ item.measure_title }}</p>
        </div>
        <!-- 用户信息 -->
        <div class="mmpi-149-userInfo" v-show="form.scope_basic == 1">
          <table>
            <tr>
              <td>
                科室：
                <span>{{ item.customer.department }}</span>
              </td>
              <td>
                姓名：
                <span>{{ item.customer.name }} </span>
              </td>
              <td>
                年龄:
                <span>{{ item.customer.ages }}</span>
              </td>
            </tr>
            <tr>
              <td>
                编号：
                <span>{{ item.customer.account }}</span>
              </td>
              <td>
                性别：
                <span>{{ item.customer.sex }}</span>
              </td>
              <!-- <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td> -->
              <td>
                文化程度：
                <span>{{ item.customer.culture }}</span>
              </td>
            </tr>
            <tr>
              <td>
                项目数:
                <span>{{
                    item.results.mmpi.statistics.dashi +
                    item.results.mmpi.statistics.dafou
                  }}</span>
              </td>
              <td>
                答是数量:
                <span>{{ item.results.mmpi.statistics.dashi }}</span>
              </td>
              <td>
                答否数量:
                <span>{{ item.results.mmpi.statistics.dafou }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                报告日期:
                <span>{{ item.customer.evaluating_time }}</span>
              </td>
            </tr>
            <template v-if="item.customer.archives">
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(0, 3)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(3, 6)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(6, 9)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item2, key) in item.customer.archives.slice(9, 12)" :key="key">
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <!-- 测评结果 -->

        <div class="mmpi-149-testResult"  v-if="false">
          <!--            <div class="mmpi-149-title">* * * 总效度分析 * * *</div> -->
            <!-- <el-divider></el-divider>-->
          <template>
            <div class="mmpi-149-testResult-item" v-for="(item2, key2) in item.factor_results.slice(0, 4)" :key="key2">
              <div class="mmpi-149-testResult-title">
                <span>{{ item2.name }}</span>
                <span>分数：{{ item2.score }}</span>
                <span>{{ item2.mark }}</span>
              </div>
              <div class="mmpi-149-testResult-content">
                【说明】<span v-html="item2.comment"></span>
              </div>
            </div>
          </template>
        </div>
        <div class="mmpi-149-testResult">
          <div class="mmpi-149-title">
            * * * 心理健康总体分析 * * *
          </div>
          <!-- <el-divider></el-divider>--><hr>
          <div class="mmpi-149-testResult-item" v-for="(item2, key2) in item.factor_results.filter((res)=>!unset_one_tag.includes(res.name))" :key="key2">
            <div class="mmpi-149-testResult-content">
              <!-- {{ key2 + 1 }}<span v-html="item2.comment"></span> -->
              {{ key2 + 1 }}、<span v-html="item2.comment"></span>
            </div>
          </div>
        </div>

        <!-- MMPI 基 本 量 表 分 -->
        <div class="mmpi-149-mmpi">
          <div class="mmpi-149-title">MMPI 量 表 分 及 结 果 分 类</div>
          <!-- <el-divider></el-divider>-->
          <div class="mmpi-149-mmpi_table">
            <!-- 2 -->
            <table>
              <tr>
                <td>
                  <span>维度</span>
                </td>
                <td v-for="(res, key, index) in item.factor_results" :key="index">
                  <span>{{ res.name.replace(/\((.*?)\)/, '') }}</span>
                  <span>{{ res.name.replace(/[^A-Za-z]/g, '') }}</span>
                </td>
              </tr>
            </table>
            <!-- 4 -->
            <table>
              <tr>
                <td>
                  <span>分数</span>
                </td>
                <td v-for="(res, key, index) in item.factor_results" :key="index">
                  <span style="writing-mode: horizontal-tb">{{ res.score }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>分类</span>
                </td>
                <td v-for="(res, key, index) in item.factor_results" :key="index">
                  <span>{{ res.mark }}</span>
                </td>
              </tr>
            </table>
          </div>
          <br/>
          <div class="page-break-after"></div>
          <!-- 图表 -->
          <div class="mmpi-149-chart" v-if="!isGraph" v-show="form.scope_graph == 1">
            <!-- 90,圆形图 -->
            <div :id="'chart-report' + key" :style="{ width: '100%', height: '100%' }"></div>
          </div>
        </div>
        <br/>
        <!--辅助诊断意见 -->
        <div class="mmpi-149-auxiliary">
          <div class="mmpi-149-auxiliary_title">* * *心理症状提示* * *</div>
          <!-- <el-divider></el-divider>--><hr>
          <div class="mmpi-149-auxiliary_main">
            <div class="mmpi-149-auxiliary_item" v-for="(r2, k2) in item.factor_results.filter((res)=>!unset_one_tag.includes(res.name))"
                 :key="k2">
              <div class="mmpi-149-item_title">
                {{ k2 + 1 }}、{{ r2.name.replace(/\((.*?)\)/, '') }}({{ r2.reference ? r2.reference : '空' }}/{{
                  r2.score
                }})：
              </div>
              <div class="mmpi-149-item_content" v-for="(str) in r2.question" v-if="str.option === str.correct">
                {{ str.title}}
              </div>
            </div>
          </div>
        </div>

        <br/>
        <!-- 总效度分析 -->
        <div class="mmpi-149-describe">
          <div class="mmpi-149-title">* * * 总效度分析 * * *</div>
          <!-- <el-divider></el-divider>--><hr>
          <div>
            <div class="mmpi-149-testResult-item"
                 v-for="(item2, key2) in item.factor_results.filter((v)=>['说谎分(L)','诈病分(F)'].includes(v.name))"
                 :key="key2">
              <div>
                <div class="mmpi-149-testResult-title">
                  <span>{{ item2.name }}</span>
                  <span>：{{ item2.score }}，</span>
                  <span>处于{{ item2.mark }}范围</span>
                </div>
                <div class="mmpi-149-testResult-content">
                  【说明】<span v-html="item2.comment"></span>
                </div>
              </div>
            </div>
            <div
                v-if="item.factor_results.filter((v)=>['说谎分(L)','诈病分(F)'].includes(v.name) && v.mark != '正常').length > 1">
              <br/>
              两者均存在异常，本次结果不可靠。
            </div>
          </div>

          <!-- 个性心理描述 -->
          <div class="mmpi-149-describe_main" v-if="item.results.conclusion.length > 0">
            <div class="mmpi-149-describe_content_title">
              <p class="mmpi-149-title" style="font-size: 18px">
                * * * 总 体 描 述 * * *
              </p>
              <!-- <el-divider></el-divider>--><hr>
            </div>
          </div>
          <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
          <div class="mmpi-149-describe_main">
            <div class="mmpi-149-describe_content">
              <p v-for="(item2, key2) in item.results.conclusion" v-html="item2">

              </p>
            </div>
          </div>
        </div>
        <hr>

        <!-- 医生签名 -->
        <div class="mmpi-149-signature">
            <span class="mmpi-149-title">
              <!-- <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评师:_______________</span
                > -->
              测评师 ：
              <span class="mmpi-149-signature_img">
                <img :src="item.admin.sign_img" alt width="150" />
              </span>
            </span>
          <!-- <span class="title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span> -->
          <span @dblclick="modificationDate">
              <span class="mmpi-149-title mmpi-149-data">报告日期 ：</span>
              <span v-show="!editStateFlag">{{ item.customer.evaluating_time }}</span>
              <span v-show="editStateFlag">
                <span class="mmpi-149-block">
                  <el-date-picker v-model="specificTime" type="datetime" @change='change' format="yyyy-MM-dd HH:mm:ss"
                                  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                  </el-date-picker>
                </span>
              </span>
            </span>
        </div>
        <div class="mmpi-149-remind">
          <p style="text-align: center">
            ******{{ disclaimer }}******
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {loadReport, save_meaconclusion} from "@/api/report.js";
import {inspectUp} from "@/api/information.js";
import {updateConfig} from "@/api/comm.js";
import "../../../../public/static/jquery.wordexport";
import {resultTime} from "@/api/comm.js";

export default {
  inject: ["reload"],
  data() {
    return {
      auxiliary_item_index: 0,
      editStateFlag: false,
      specificTime: "",
      reference_range: [],
      theme: "0",
      // 新报告,控制打印
      bool: false,
      // 旧报告
      bool1: false,
      bool3: false,
      speciChartValue: {},
      iszp: false,
      zpcomment: "",
      base_results: {},
      isprint: 1,
      print_no_inspect: 1,
      islist: false,
      isDegree: false,
      degree: "", //配合程度
      config: {},
      tmp: {},
      form: {},
      SetReportBox: false,
      setReportForm: [],
      fullscreenLoading: true,
      disclaimer: localStorage.getItem('config')['disclaimer'] || JSON.parse(localStorage.getItem('config'))['disclaimer'],// 免责声明
      uid: {},
      // ----------新--------------------
      // 图表数据
      chartDataList: [],
      // 表数据
      tableInfoList: [],
      listData1: [], //数值
      listData2: [], //参考值
      listData3: [], //图示名
      fontSize: "16px", // 字体大小
      wordImgUrl: [], //图标base64
      signatureImg: [], //签名base64
      report_sign: "", //签名base64
      // 编辑状态
      editState: false,
      // 等待下载
      downLoad: false,
      downLoadNum: 0,
      downLoadTitle: "",
      //zip数据
      zipData: null,
      // 是否显示报告结果
      isResultReport: false,
      // 是否显示程度，与分数结果
      isLevel: false,
      // 是否显示得分
      isScore: false,
      //  是否显示测试结果
      isGraph: false,
      scaleName: "",
      scoreText: "",
      isDisableFactor: false,
      reportReportShow: [""], // 特殊报告展示
      // 多表格数据
      tableInfoListBox: [],
      tableInfoListFlag: false,
      // 保存总评
      base_results2: {},
      // 总体分析说明和症状提示 需要排除的因子
      unset_one_tag :['说谎分(L)','诈病分(F)','校正分(K)'],
    };
  },
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
      //单
      this.isDegree = false; // 配合程度
      data.id = this.props_id || this.$route.query.id;
      this.uid.id = this.$route.query.id;
    // 发起请求渲染报告
    loadReport(data).then((res) => {
      this.print_no_inspect = res.print_no_inspect * 1;
      this.isprint = res.data.inspect * 1;
      this.theme = res.data.theme;
      this.tmp = JSON.parse(JSON.stringify(res.config)); //获取-全局配置信息
      // 判断是管理员还是用户端
      if (this.tmp.user_scope_basic) {
        this.form.scope_assessor = res.config.user_scope_assessor;
        this.form.scope_basic = res.config.user_scope_basic;
        this.form.scope_explain = res.config.user_scope_explain;
        this.form.scope_factor = res.config.user_scope_factor;
        this.form.scope_graph = res.config.user_scope_graph;
        this.form.scope_score = res.config.user_scope_score;
        this.form.scope_suggest = res.config.user_scope_suggest;
        this.form.scope_question = res.config.user_scope_question;
        this.form.scope_measure_explain = res.config.user_scope_measure_explain === undefined ? '1' : res.config.user_scope_measure_explain;
        // 总评
        this.form.scope_appraisal = res.config.user_appraisal;
      } else {
        this.form = res.config; //打印设置
        this.form.scope_measure_explain = res.config.scope_measure_explain === undefined ? '1' : res.config.scope_measure_explain;

      }
      if (this.$route.query.id) {
        res.data.factor_results = res.data.factor_results.filter((v)=>{
          var sex = res.data.customer.sex === '男' ? '女' : '男' ;
          return v.name.indexOf(sex) === -1 ;
        })
        this.tableInfoList = [res.data];
      }

      // 1-图表数据处理
      let resArr = [];
      let imgBase64 = [];
      this.tableInfoList.forEach((v, k) => {
        if (v.admin.sign_img instanceof Object) {
          this.report_sign = "";
        } else {
          this.report_sign = v.admin.sign_img;
        }

        // --------------------------- 签名图片处理--------------------
        this.convertImgToBase64(v.admin.sign_img, (base64Img) => {
          imgBase64.push(base64Img);
        });
      });


      //----------------T顺序表排序--------------------------
      this.tableInfoList = this.tableInfoList.filter((item, index) => {
        if (item?.results?.tcore?.core) {
          let myobj = item.results.tcore.core;
          let tempobj = {};
          //l,f,k不参与排序
          tempobj.L = myobj["L"];
          tempobj.F = myobj["F"];
          tempobj.K = myobj["K"];
          delete myobj["L"];
          delete myobj["F"];
          delete myobj["K"];
          let result = Object.values(myobj).sort((a, b) => {
            return b - a;
          });
          let new_obj = {};
          for (let i = 0; i < result.length; i++) {
            // 将原对象中的键值对按照属性值的排序顺序写入新对象
            Object.keys(myobj).map((item, index) => {
              if (myobj[item] === result[i]) {
                new_obj[item] = result[i];
              }
            });
          }
          new_obj.L = tempobj["L"];
          new_obj.F = tempobj["F"];
          new_obj.K = tempobj["K"];
          return (item.results.tcore.core = new_obj);
        } else {
          return item;
        }
      });
      this.chartDataList = resArr;
      this.signatureImg = imgBase64;


      if (res.code === 400200 && !res.data.extra) {

        let {
          disableAnalysis = false,
          disableScore = false,
          disableLevel = false,
          disableGraph = false,
          scoreText = "",
          disableFactor = false,
        } = res.data?.extra?.display;
        //因子表
        this.isResultReport = disableAnalysis || false;
        this.isResultReport = disableAnalysis ?? true;

        this.isLevel = disableLevel || false;
        this.isLevel = disableLevel ?? true;

        this.isScore = disableScore || false;
        this.isScore = disableScore ?? true;
        this.isGraph = disableGraph || false;
        this.isGraph = disableGraph ?? true;
        this.scoreText = scoreText || "";
        this.isDisableFactor = disableFactor || false;
        this.isDisableFactor = disableFactor ?? true;
      }

    });
  },
  mounted() {

    this.$nextTick(() => {
      this.charData();
      // 字体大小设置
      let fontS =
          JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // ----------------------
    });
  },
  methods: {
    // 修改日期
    modificationDate() {
      this.editStateFlag = !this.editStateFlag
    },
    // 修改日期
    change() {
      let time = (new Date(this.specificTime)).getTime() / 1000
      resultTime({id: this.$route.query.id, time: time}).then(res => {
        if (res.code == 400200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.tableInfoList[0].customer.evaluating_time = this.specificTime
          // console.log("原有时间",this.tableInfoList[0].customer.evaluating_time);
          this.editStateFlag = false
        } else {
          this.$message.error('修改日期失败！');
        }

      })

    },
    connotPrint2() {
      if (!this.print_no_inspect && !this.isprint) {
        this.$message({
          message: "未审核不能打印",
          type: "error",
        });
      }
    },
    connotPrint() {
      if (!this.print_no_inspect) {
        //未审核不能打印
        if (this.isprint) {
          let {href} = this.$router.resolve({
            path: "/downpdf",
            query: this.uid,
          });
          window.open(href, "_blank");
          // this.$router.push({ path: "/downpdf", query: this.uid });
        } else {
          this.$message({
            type: "error",
            message: "未审核不能打印",
          });
        }
      } else {
        let {href} = this.$router.resolve({
          path: "/downpdf",
          query: this.uid,
        });
        window.open(href, "_blank");
      }
    },

    // 审核
    upInspect() {
      let id = this.$route.query.id || this.$route.query.ids;
      this.$confirm("是否审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            inspectUp({id}).then((res) => {
              if (res.code == 400200) {
                this.isprint = 1;
                // row.inspect = 1;
                this.$message({
                  type: "success",
                  message: "审核成功!",
                });
                this.refresh();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
    },
    // 重置数据
    resc() {
      this.form = JSON.parse(JSON.stringify(this.tmp));
    },
    // 保持打印设置
    onSubmit() {
      // console.log("发送参数",this.form);
      updateConfig(this.form).then((res) => {
        if (res.code == 400200) {
          this.SetReportBox = false;
          this.form = this.form;
          this.tmp = JSON.parse(JSON.stringify(this.form));
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
        }
      });
    },
    // 控制打印设置
    showSetReportBox() {
      this.SetReportBox = true;
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    auxiliary_item_index_fun() {
      return 1;
      this.auxiliary_item_index++
      return this.auxiliary_item_index;
    },
    //   图表1
    charData() {
      let self = this;
      for (let i = 0; i < this.tableInfoList.length; i++) {
        // --------基于准备好的dom，初始化echarts实例---------
        let ageData = [];
        ageData[i] = this.$echarts.init(
            document.getElementById(`chart-report${i}`)
        );
        this.myChart = ageData;
        //---------------- 处理特殊量表报告-----------------
        if (
            this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表-399" ||
            this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表-149" ||
            this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表"
        ) {
          //  图表数据处理
          let xName = []; //x轴 -下
          let x2Name = []; //x轴 -上
          let yData = [];
          //==========11人民医院特殊处理=================
          let xyObj = [];
          let newxName = []; //x轴 -下
          let newx2Name = []; //x轴 -上
          let newyData = [];

          for (let k1 in this.tableInfoList[i].results.mmpi) {
            // 图中数据
            //kCmCore+加k，kNoCmCore不加k
            if (k1 == "kNoCmCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                if (this.tableInfoList[i].results.mmpi[k1][k2] == "") {
                  yData.push(0);
                } else {
                  yData.push(this.tableInfoList[i].results.mmpi[k1][k2]);
                }
              }
            }
            if (k1 == "oldCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                xName.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
            if (k1 == "help") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                x2Name.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
          }

          for (let i = 0; i < xName.length; i++) {
            if (x2Name[i] != "Q") {
              let xyTmp = {
                x: xName[i],
                x2: x2Name[i],
                y: yData[i],
              };
              xyObj.push(xyTmp);
              // xyObj = xyObj.sort((a, b) => {
              //   return b.y - a.y;
              // });
            }
          }
          for (let i = 0; i < xyObj.length; i++) {
            newxName.push(xyObj[i].x);
            newx2Name.push(xyObj[i].x2);
            newyData.push(xyObj[i].y);
          }
          xName = newxName;
          x2Name = newx2Name;
          yData = newyData;

          let yMax = Math.max.apply(null, yData) + 10;
          ageData[i].setOption(this.createPouMianTuData(this.tableInfoList[i]));
          // 转出ba64格式图片
          let imgURL = ageData[i].getDataURL({
            pixelRatio: 1,
          });
          this.wordImgUrl.push(imgURL);
          // 开始下次循环
          continue;
        }

        let data1 = this.listData1[i];
        let data3 = this.listData3[i];
        let dataMax = Math.max(...data1.map(Number));
        // 传到下级的图形数据
        this.speciChartValue = {data1, data3, dataMax, range: []};
        // 第一处
      }
    },
    createPouMianTuData(data) {
      /**
       * {
       *     "name": "说谎分数(L)",
       *     "score": 15,
       *     "factor_id": 2440,
       *     "conclusion_id": 5698,
       *     "mark": "异常",
       *     "reference": "",
       *     "comment": "提示受试者症状汇报不真实问卷回答相对无效，也可能是受试者对自己要求过高。",
       *     "advice": "",
       *     "graph_show": 1
       * }
       */

      let x_data = [];
      let a_data = [];
      let b_data = [];
      let c_data = [];
      let diff = [];
      let lowest_data = [];
      data.factor_results.forEach((v) => {
        if (v.reference) {
          x_data.push(v.name.replace(/\((.*?)\)/, ''));
          a_data.push(v.score);
          b_data.push(v.floor);
          c_data.push(v.upper);
          lowest_data.push(Math.min(v.score, v.floor, v.upper));
          diff.push(Math.max(v.score, v.floor, v.upper) - Math.min(v.score, v.floor, v.upper));
        }
      })
      let option = {
        backgroundColor: "#fff",
        color: "#FF9F7F",
        title: {
          text: "剖 面 图",
          x: "center",
          top: -5,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(67,100,247,0.08)'
            }
          },
          formatter: function (params) {
            let showlabel = params[0].seriesName + ':' + params[0].value + '<br />' +
                params[1].seriesName + ':' + params[1].value + '<br />' +
                params[2].seriesName + ':' + params[2].value;
            return showlabel;
          },
        },
        legend: {
          data: ['分数', '偏高界限', '异常界限',],
          bottom: 0,
        },
        grid: {
          left: '0%',
          height: 300,
          containLabel: true
        },
        xAxis: [{
          axisLine: {
            lineStyle: {
              color: '#666'
            }
          },
          type: 'category',
          // axisTick: {
          //   show: false,
          //   alignWithLabel: true
          // },

          data: x_data,
          axisLabel: {
            interval: 0, //强制显示文字
            width: 12,
            overflow: 'breakAll',
            height: 10 // 设置最大高度，以防止文本过长
          }
        }],
        yAxis: [
// axisLabel: {
//     color: 'red', // 设置刻度数值的颜色
//   },
          {
            type: 'value',
            name: '票数',
            splitLine: {
              show: true
            },
            axisTick: {
              show: true
            },
            //                 splitLine: {
            //   show: true, // 设置为 true 显示 X 轴的刻度线
            // },
            axisLine: {
              lineStyle: {
                color: 'rgba(0,0,0,0)'
              },
            },
            position: 'left',
            axisLabel: {
              formatter: '{value} ',
              color: 'black',
            }
          },

        ],
        series: [
          {
            name: '分数',
            type: 'line',
            symbol: 'rect',
            symbolSize: [10, 4], // 自定义长方形的宽度和高度
            yAxisIndex: 0,
            itemStyle: {
              color: 'black',
              barWidth: 10
            },
            data: a_data
          },

          {
            name: '异常界限',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: [10, 4], // 自定义长方形的宽度和高度
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: 'red', // 设置散点的颜色
              }
            },
            data: b_data
          },
          {
            name: '偏高界限',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: [10, 4], // 自定义长方形的宽度和高度
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: 'rgb(77,196,194)', // 设置散点的颜色为透明
              }
            },
            data: c_data
          },
          {
            name: '下限',
            type: 'bar',
            stack: '使用情况',
            data: lowest_data, // 最低分
            itemStyle: {
              normal: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)'
              },
            }
          },
          {
            name: '上限',
            type: 'bar',
            barWidth: 1,
            stack: '使用情况',
            data: diff, // 最高分减去最低分的差值
            itemStyle: {
              normal: {
                color: 'rgb(252,201,218)'
              },
            }
          },
        ]
      };
      return option;
    },
    convertImgToBase64(url, callback, outputFormat) {
      var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d"),
          img = new Image();
      img.crossOrigin = "*";
      img.src = url;
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat || "image/png");
        callback.call(this, dataURL);
        canvas = null;
      };
    },
    // 编辑内容
    infoEditState() {
      this.editState = true;
    },
    // 保存修改的内容
    seveEditInfo() {
      this.editState = false;
      // 数据处理
      let data = JSON.parse(JSON.stringify(this.tableInfoList[0]));

      if (this.iszp) {
        if (this.theme >= 20 && this.theme != 88) {
          data.factor_results.shift();
        } else {
          data.base_results = this.base_results;
          data.base_results.comment = this.zpcomment;
          data.factor_results.shift();
        }
      } else {
        data.base_results = data.factor_results[0];
        data.factor_results.shift();
      }

      let a = {
        id: this.$route.query.id,
        data,
      };
      save_meaconclusion(a).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
      });
    },
    // 排序
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },
  },
  computed: {
    getzylist() {
      return function (ztitem) {
        if (ztitem.hasOwnProperty("desc")) {
          if (ztitem.desc) {
            return ztitem.desc;
          } else {
            if (ztitem.hasOwnProperty("list")) {
              if (Array.isArray(ztitem.list)) {
                this.islist = true;
              } else {
                return ztitem.desc || "";
              }
            } else {
              return ztitem.desc;
            }
          }
        } else {
          if (ztitem.hasOwnProperty("list")) {
            if (Array.isArray(ztitem.list)) {
              this.islist = true;
            } else {
              return ztitem.desc || "";
            }
          } else {
            return ztitem.desc || "";
          }
        }
      };
    },
  },
  filters: {
    waiting(val) {
      return val != 1 ? "正常" : "危险";
    },
    titleFilter(index) {
      let title = "";
      if (index === 0) {
        return "测谎";
      } else if (index === 1) {
        return "总效度分析";
      } else if (index === 2) {
        return "";
      }
    },
  },
  props: {
    props_id: {
      type: Number | String,
      default() {
        return 0;
      },
    },
  },
  watch: {
    chartDataList: function () {
      this.$nextTick(() => {
        if (!this.isGraph) {
          this.charData();
        }
        this.fullscreenLoading = false;
      });
    },
  },
};
</script>
<style lang="less">
// 报告
.tc-report-main-mmpi-149 {

  width: 100%;

  // 报告内容部分
  .mmpi-149-content {
    max-width: 900px;
    margin: auto;
    background: white;
    padding: 20px 60px;
    box-sizing: border-box;

    // 报表头部
    .mmpi-149-report-title {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }

    // 报表头部
    .mmpi-149-unit-title {
      font-size: 27px;
      font-weight: 600;
      text-align: center;

      div {
        width: 60%;
        margin-left: 20%;
        margin-bottom: 10px;;
        border: 1px black solid;
      }
    }

    //   用户信息
    .mmpi-149-userInfo {
      margin-top: 20px;

      table {
        margin: auto;
        width: 100%;
        border-top: 1px black dashed;
        border-left: 1px black dashed;

        tr {
          width: 100%;
          // font-size: 16px;
          font-weight: 700;

          td {
            padding: 5px;
            border-bottom: 1px black dashed;
            border-right: 1px black dashed;

            &:nth-child(1) {
              width: 220px;
            }

            &:nth-child(2) {
              width: 180px;
            }

            &:nth-child(3) {
              width: 300px;
            }

            span {
              // font-size: 14px;
              font-weight: 300;
            }
          }
        }
      }
    }

    //   测试结果标题.
    .mmpi-149-test-msg {
      padding-left: 0px;
      margin-top: 10px;
      font-size: 25px;
      color: #095080;

      font-weight: 700;
    }

    //    图表
    .mmpi-149-chart {
      margin-top: 10px;
      height: 450px !important;

      #chart-report {
        margin: 0 auto;
        //   border: black 1px solid;
      }
    }

    //   表格
    .mmpi-149-table {
      overflow: hidden;
      margin-top: 15px;
      box-sizing: border-box;
      padding: 20px 0;
      border-top: 1px black solid;
      border-bottom: 1px black solid;

      .mmpi-149-table-main {

        .mmpi-149-one,
        .mmpi-149-two {
          width: 100%;
          border-top: 1px black dashed;
          border-left: 1px black dashed;

          tr {
            width: 100%;

            th {
              border-right: 1px black dashed;
              border-bottom: 1px black dashed;
              padding: 5px 0;
            }

            td {
              text-align: center;
              border-right: 1px black dashed;
              border-bottom: 1px black dashed;
              padding: 5px 0;
              font-size: 14px;

              &:nth-child(1) {
                // width: 180px;
              }

              &:nth-child(2) {
                // width: 150px;
              }

              &:nth-child(3) {
                min-width: 40px;
                text-align: center;
              }

              .mmpi-149-table-icon {
                padding-left: 5px;
              }
            }
          }
        }

        .mmpi-149-one {
        }

        .mmpi-149-two {
          margin-left: -3px;
          border-left: 1px black dashed;
        }

        .mmpi-149-results-two {
          width: 100%;
          border-top: 1px dashed black;
          border-left: 1px dashed black;

          tr {
            th {
              padding: 5px 0;
              border-bottom: 1px dashed black;
              border-right: 1px dashed black;

              &:nth-child(1) {
                width: 160px;
              }

              &:nth-child(2) {
                width: 180px;
              }

              &:nth-child(3) {
                width: 50px;
              }

              &:nth-child(4) {
                width: 160px;
              }

              &:nth-child(5) {
                width: 180px;
              }

              &:nth-child(6) {
                width: 50px;
              }
            }

            td {
              box-sizing: border-box;
              padding: 2px 5px 2px 5px;
              vertical-align: middle;
              border-bottom: 1px dashed black;
              border-right: 1px dashed black;
              text-align: center;

              &:nth-child(1) {
                width: 160px;
              }

              &:nth-child(2) {
                width: 180px;
              }

              &:nth-child(3) {
                width: 50px;
              }

              &:nth-child(4) {
                width: 160px;
              }

              &:nth-child(5) {
                width: 180px;
              }

              &:nth-child(6) {
                width: 50px;
              }
            }
          }
        }
      }
    }

    //   健康状态
    .mmpi-149-condition {
      margin-top: 10px;
      border-bottom: 1px black solid;
      padding-bottom: 14px;

      table {
        tr {
          td {
            text-indent: 2em;
            letter-spacing: 3px;
            line-height: 25px;
            // font-size: 15px;
          }
        }

        .mmpi-149-condition-title {
          text-indent: 0;
          padding-top: 10px;
          // font-size: 16px;
          font-weight: 700;
        }
      }
    }

    //   每项分析
    .mmpi-149-analyze {
      margin-top: 20px;
      padding-bottom: 10px;

      table {
        padding: 10px 0;
        border-bottom: 1px black solid;
        width: 100%;

        tr {
          td {
            text-indent: 2em;
            letter-spacing: 3px;
            line-height: 25px;
            // font-size: 15px;
          }
        }

        .mmpi-149-analyze-title {
          text-indent: 0;
          padding-top: 10px;
          // font-size: 16px;
          font-weight: 700;

          .mmpi-149-el-input {
            width: 100px;

            &:nth-child(1) {
              width: 180px;
            }

            &:nth-child(2) {
              width: 180px;
            }

            &:nth-child(3) {
              width: 100px;
            }
          }
        }
      }
    }

    .mmpi-149-degree {
      margin: 10px 0;

      p {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    // 签名
    .mmpi-149-signature {
      position: relative;
      text-align: right;
      padding-top: 40px;

      .mmpi-149-title {
        font-weight: 700;

        .mmpi-149-signature_img {
          display: inline-block;
          min-width: 100px;
        }
      }

      .mmpi-149-data {
        margin-left: 20px;
      }

      img {
        // position: absolute;
        // top: -40px;
        // left: 284px;
      }
    }
  }

  // 明尼苏达
  .mmpi-149-minnesota {
    .mmpi-149-title {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }

    .mmpi-149-testResult {
      //margin-top: 30px;
      font-size: 16px;

      .mmpi-149-testResult-item {
        margin-bottom: 15px;

        .mmpi-149-testResult-title {
          font-weight: 700;

          span {
            margin-right: 10px;
          }
        }

        .mmpi-149-testResult-content {
          margin-top: 2px;

          span {
            line-height: 22px;
          }
        }
      }
    }

    .mmpi-149-remind {
      border-top: 1px black solid;
      border-bottom: 1px black solid;
      padding: 5px 0;
    }

    .mmpi-149-el-divider--horizontal {
      margin: 5px 0;
    }

    .mmpi-149-el-divider {
      background-color: rgb(133, 133, 133);
    }

    .mmpi-149-mmpi {
      margin-top: 40px;

      .mmpi-149-mmpi_table {
        margin-top: 20px;

        table {
          border-spacing: 0;
          border-collapse: collapse;
          border-top: black 1px solid;
          border-left: black 1px solid;

          td {
            border-bottom: black 1px solid;
            border-right: black 1px solid;

            &:nth-child(1) {
              box-sizing: border-box;
              padding-left: 3px;
            }
          }
        }

        table:nth-child(1) {
          width: 100%;
          font-size: 14px;

          tr:nth-child(1) {
            display: flex;

            td {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //justify-content: sp;
              &:nth-child(1) {
                //flex: 2.2;
                text-align: left;
                justify-content: flex-start;
                box-sizing: border-box;
                align-items: flex-start;
              }

              span {
                &:nth-of-type(1) {
                  display: inline-block;
                  //text-align: center;
                  height: 120px;
                  writing-mode: vertical-lr;
                  letter-spacing: 8px;
                  padding-top: 10px;
                }

              }
            }
          }

          tr:nth-child(2) {
            display: flex;

            td {
              flex: 1;
              display: flex;
              justify-content: center;

              &:nth-child(1) {
                //flex: 2.2;
                text-align: left;
                justify-content: flex-start;
              }

              span {
                display: inline-block;
                text-align: center;
                padding: 8px 0;
              }
            }
          }
        }

        table:nth-child(2) {
          width: 100%;
          font-size: 14px;
          border-top: 0;

          tr {
            display: flex;

            td {
              flex: 1;
              display: flex;
              justify-content: center;

              &:nth-child(1) {
                //flex: 2.2;
                text-align: left;
                justify-content: flex-start;
              }

              span {
                writing-mode: vertical-lr;
                display: inline-block;
                text-align: center;
                padding: 8px 0;
              }
            }
          }
        }

        .mmpi-149-mmpi_summarize {
          padding: 10px 0;
        }
      }
    }

    .mmpi-149-t {
      margin-top: 40px;

      .mmpi-149-t_table {
        margin-top: 20px;

        > table:nth-child(1) {
          width: 100%;
          font-size: 14px;

          tr {
            display: flex;

            > td {
              flex: 1;
              display: flex;
              justify-content: center;

              > span {
                display: inline-block;
                text-align: center;
                padding: 5px 0;
              }

              &:nth-of-type(11) {
                margin-left: 30px;
              }
            }
          }
        }

        > table:nth-child(2) {
          width: 100%;
          font-size: 14px;
          border: black 1px solid;
          border-collapse: collapse;

          tr {
            &:nth-child(1) {
              td:nth-child(4) {

                // display: flex;
                span {
                  display: inline-block;
                  width: 9%;
                  justify-content: space-around;
                  position: relative;
                  text-align: center;

                  &::after {
                    font-size: 17px;
                    content: "|";
                    position: absolute;
                    bottom: -16px;
                    right: 9.5px;
                    z-index: 999;
                  }

                  &::before {
                    font-size: 10px;
                    content: "|||||||||";
                    position: absolute;
                    bottom: -11px;
                    right: -3px;
                    z-index: 999;
                  }
                }
              }
            }

            td {
              text-align: center;
              border-right: black 1px solid;
              border-bottom: black 1px solid;

              &:nth-child(1) {
                width: 100px;
              }

              &:nth-child(2) {
                width: 50px;
              }

              &:nth-child(3) {
                width: 180px;
              }

              &:nth-child(4) {
                position: relative;
                text-align: left;
                font-size: 10px;
              }

              &:nth-child(5) {
                width: 150px;
              }
            }
          }

          .mmpi-149-cursor {
            background: rgb(63, 62, 62);
            color: rgb(63, 62, 62);
            display: inline-block;
            position: absolute;

            .mmpi-149-el-icon-caret-top {
              font-size: 20px;
            }
          }
        }

        .mmpi-149-forecast {
          margin-top: 30px;

          table {
            width: 100%;

            caption {
              font-size: 16px;
              font-weight: 700;
              padding: 10px 0;
            }

            tr {
              &:nth-child(2) {
                font-weight: 700;

                td {
                  &:nth-child(1) {
                    width: 120px;
                  }
                }
              }

              td {
                width: 50px;
                text-align: right;
              }
            }
          }
        }
      }
    }

    .mmpi-149-conclusion {
      margin-top: 40px;

      table {
        width: 100%;
        border-collapse: collapse;

        caption {
          padding: 5px 0;
          font-weight: 700;
          font-size: 18px;
        }

        tr {
          th {
            border-top: 1px black solid;
            border-bottom: 1px black solid;
            padding: 5px 0;

            &:nth-child(1) {
              width: 200px;
              text-align: center;
            }

            &:nth-child(2) {
              width: 150px;
              text-align: center;
            }

            &:nth-child(3) {
            }
          }

          td {
            font-size: 14px;
            padding: 5px 0;

            // vertical-align: top;
            &:nth-child(1) {
            }

            &:nth-child(2) {
              text-align: center;
            }
          }
        }
      }
    }

    .mmpi-149-auxiliary {
      margin-top: 40px;

      .mmpi-149-auxiliary_title {
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
      }

      .mmpi-149-auxiliary_main {
        .mmpi-149-auxiliary_item {
          padding: 10px 0;

          .mmpi-149-item_title {
            font-weight: 700;
            margin-bottom: 8px;
          }
        }
      }
    }

    .mmpi-149-describe {
      //margin-top: 40px;

      .mmpi-149-describe_main {
        .mmpi-149-describe_content_title {
          font-size: 16px;
          text-align: center;

          p {
            margin: 30px 0px 9px 0px;
            font-size: 16px;
            font-weight: 700;
          }
        }

        .mmpi-149-describe_content {
          font-size: 14px;
          padding: 10px 0;
        }
      }
    }

    .mmpi-149-zDescribe {
      margin-top: 60px;

      .mmpi-149-zDescribe_title {
        font-weight: 700;
        text-align: center;
      }

      .mmpi-149-zDescribe_content {
        margin-top: 40px;
        font-size: 14px;
      }

      .mmpi-149-zDescribe_msg {
        margin-top: 50px;

        p {
          font-weight: 700;

          span {
            font-weight: 100;
          }
        }
      }
    }

    .mmpi-149-suggest {
      border-top: 1px black solid;
      margin-bottom: 5px;
      padding: 5px 0;

      .mmpi-149-suggest_msg {
        font-weight: 700;
      }

      .mmpi-149-suggest_content {
        height: 200px;
      }
    }
  }

  .mmpi-149-report-size {
    font-size: 16px !important;
  }
}


@media screen and (max-width: 768px) {
  .report {
    zoom: 0.55;
    overflow: auto;
  }

}

/* 针对打印样式的规则 */
@media print {
  ///标签上方分页
  .page-break-after {
    page-break-after: always;
  }
  //标签下方分页
  .page-break-before {
    page-break-before: always;
  }
  //标签单独一页
  .page-break {
    page-break-before: always;
    page-break-after: always;
  }
}
</style>